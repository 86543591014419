<template >
  <a-spin
    :spinning="spinning"
    class="max-width"
    style="min-height: 600px;max-width:1600px;padding: 0 50px"
  >
    <a-row type="flex" style="margin: 20px auto; max-width: 1600px;">
      <a-breadcrumb>
        <a-breadcrumb-item>
          <router-link :to="'/'">
            <a-icon type="home" />首页
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <router-link :to="'/orders'">我的订单</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>订单详情</a-breadcrumb-item>
      </a-breadcrumb>
    </a-row>
    <!-- 产品列表 -->
    <a-divider class="theme" orientation="center">订单产品列表</a-divider>
    <div v-for="supplier in data.carts" :key="supplier.cart[0].id_order_cart">
      <a-table
        :columns="[
        { title: '产品', dataIndex: 'url', scopedSlots: { customRender: 'url' }, width: 110 },
        { title: '名称', dataIndex: 'name', scopedSlots: { customRender: 'name' },width: 250 },
        { title: '数量', dataIndex: 'amount' },
        { title: '单位', dataIndex: 'product_unit' },
        { title: '小计', dataIndex: 'price_settle', customRender: (text, record) => (record.amount * record.price_settle).toFixed(2) },
        { title: '订单备注', dataIndex: 'note', scopedSlots: { customRender: 'note' }, width: 200 }
      ]"
        size="small"
        :row-key="record => record.id_order_cart"
        :data-source="supplier.cart"
        :pagination="false"
      >
        <!-- 图片 -->
        <span slot="url" slot-scope="text">
          <div class="image">
            <img mode="aspectFix" :src="text" />
          </div>
        </span>
        <!-- 名称 -->
        <span slot="name" slot-scope="text, record">
          <router-link :to="{ name: 'product', query: { id: record.id_product_info }}">
            <a class="theme" style="text-decoration: underline;">{{ text }}</a>
          </router-link>
          <br />
          <span>￥{{ record.price_settle }}</span>
        </span>
        <!-- 备注 -->
        <span slot="note" slot-scope="text">{{ text }}</span>
        <template slot="footer">运费描述：{{ supplier.cart[0].freight_note || '暂无运费描述' }}</template>
      </a-table>
      <br />
    </div>

    <!-- 产品合计 -->
    <div class="buy">
      <span class="grey" style="line-height: 50px">
        共
        <span class="red">{{ this.length }}</span>
        件产品
      </span>
      <a-row type="flex">
        <div class="red" style="line-height: 50px;">
          合计 ￥
          <span style="font-size:27px" class="bold">{{ this.real_money }}</span>
        </div>
      </a-row>
    </div>
    <!-- 收货信息 -->
    <a-divider class="theme" orientation="center">收货信息</a-divider>
    <a-descriptions :column="1" size="small" bordered>
      <a-descriptions-item label="门店名称">{{ this.franchisee.hotel_name }}</a-descriptions-item>
      <a-descriptions-item label="收货人及电话">{{ this.franchisee.contact }}</a-descriptions-item>
      <a-descriptions-item label="收货地址">{{ this.franchisee.shipping_info }}</a-descriptions-item>
    </a-descriptions>
    <!-- 收货信息 -->
    <a-divider class="theme" orientation="center">确认开票信息</a-divider>
    <!-- <div style="text-align: center">
      <a-button
        :disabled="invoiceType !== i.id "
        style="margin: 20px"
        v-for="i in invoiceInfo"
        :key="i.id"
        :type="invoiceType === i.id ? 'primary' : ''"
      >{{ i.title }}</a-button>
    </div>-->
    <a-descriptions :column="2" size="small" bordered>
      <a-descriptions-item label="发票抬头">{{ this.franchisee.company_name }}</a-descriptions-item>
      <a-descriptions-item label="发票接收邮箱">{{ this.franchisee.email }}</a-descriptions-item>
      <a-descriptions-item label="纳税人识别号">{{ this.franchisee.company_code }}</a-descriptions-item>
      <a-descriptions-item label="公司注册地址">{{ this.franchisee.company_address }}</a-descriptions-item>
      <a-descriptions-item label="发票种类">增值税专用发票</a-descriptions-item>
      <a-descriptions-item label="开户行及账号">{{ this.franchisee.bank_number }}</a-descriptions-item>
    </a-descriptions>
    <!-- 支付方式 -->
    <a-divider class="theme" orientation="center">支付方式</a-divider>
    <div style="text-align: center">
      <a-button type="primary">对公转账（默认）</a-button>
    </div>
    <!-- 注意事项 -->
    <a-divider class="theme" orientation="center">注意事项</a-divider>
    <ol>
      <li
        :key="item"
        v-for="item in [
          '本下单账号仅供账号使用人自己使用，如非本账号使用人使用，则本人承诺已经获得了账号使用人充分的授权。',
          '本告知书提交后，相对应的订单也将一并提交，订单提交后，则视为本人已发出一个有效的订单承诺，在供应商确认后，则视为《订单合同》生效。本人已经知悉并理解《订单合同》及告知书的全部内容，并同意接受其约束。',
          '本人确认，舒悦商城已就限制本人权利或舒悦商城单方拥有某些权利的所有条款向本人作出相应提示和说明，本人对所有条款完全理解并自愿接受该内容。',
        ]"
      >
        <p>{{ item }}</p>
      </li>
    </ol>
    <div style="text-align: center; padding: 30px">
      <a-button style="padding: 0 40px" @click="$router.go(-1)">
        <a-icon type="left" />返回
      </a-button>
    </div>
  </a-spin>
</template>
<script>
export default {
  data() {
    return {
      invoiceInfo: [
        { title: '不开具发票', id: 1 },
        { title: '增值税专用发票', id: 2 },
        { title: '增值税普通发票（电子）', id: 3 },
        { title: '增值税普通发票（纸质）', id: 4 }
      ],
      data: [], // 选中的产品
      userInfo: {}, // 收货信息
      invoiceType: null, // 发票类型
      isConfirmed: false, // 是否打钩
      isShowNoteModal: false, // 显示订单备注修改弹框
      isLoadNoteModal: false,
      isShowContactModal: false, // 显示收货人修改弹框
      isLoadContactModal: false,
      modalData: {},
      spinning: true,
      real_money: null,
      length: 0, // 商品件数
      franchisee: [] // 存放加盟商信息
    }
  },

  mounted() {
    const code = this.$route.query.contract
    this.get('getSupplierMasterForFranchisee', {
      code: code
    })
      .then((res) => {
        this.data = res
        this.franchisee = res.franchisee
        this.real_money = res.real_money
        this.spinning = false
        for (var j = 0; j < res.carts.length; j++) {
          this.length += res.carts[j].cart.length
        }

        for (var i = 0; i < this.invoiceInfo.length; i++) {
          if (this.invoiceInfo[i].title === res.headquarters.invoice_type) {
            this.invoiceType = this.invoiceInfo[i].id
          }
        }
      })
      .catch((err) => {
        this.$message.error(err.msg)
      })
  },

  methods: {}
}
</script>

<style scoped>
.image {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image img {
  max-width: 100%;
  max-height: 70px;
}
.buy {
  padding: 0 20px;
  width: 100%;
  margin-bottom: -20px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.theme {
  margin-top: 40px;
}
</style>
